import React from 'react';
import packageInfo from '../../../../package.json';
import { GlobalContentLocalizedContent } from '../../../services/localization/interfaces';

const Footer = (props: {
  clientId?: string;
  globalContent: GlobalContentLocalizedContent;
  footerUrl: {
    privacy: string;
    cookies: string;
  };
}): JSX.Element => {
  const { globalContent, footerUrl } = props;

  return (
    <div className='container-footer-bar'>
      <div className='footer-bar-top'>
        <div className='footer-phone'>
          <div style={{ display: 'flex', gap: '15px' }}>
            <a href={footerUrl.privacy} target='_blank' rel='noreferrer'>
              {globalContent?.privacy}
            </a>
            <p className='separator'>|</p>
            <a href={footerUrl.cookies} target='_blank' rel='noreferrer'>
              {globalContent?.cookies}
            </a>
          </div>
        </div>
        <div className='footer-bar'>
          <p className='footer-desktop'>© Copyright Sodexo {new Date().getFullYear()}</p>
          <p className='footer-tablet2'>© Sodexo {new Date().getFullYear()}</p>
          <p className='separator-desktop'>|</p>
          <div className='footer-desktop first-letter-uppercase'>
            <a href={footerUrl.privacy} target='_blank' rel='noreferrer'>
              {globalContent?.privacy_policy}
            </a>
          </div>
          <p className='separator-desktop'>|</p>
          <div className='footer-desktop'>
            <a href={footerUrl.cookies} target='_blank' rel='noreferrer'>
              {globalContent?.cookie_policy}
            </a>
          </div>
          <p className='separator-tablet'>|</p>
          <div className='footer-tablet'>
            <div style={{ display: 'flex', gap: '15px' }}>
              <a href={footerUrl.privacy} target='_blank' rel='noreferrer'>
                {globalContent?.privacy}
              </a>
              <p className='separator-tablet'>|</p>
              <a href={footerUrl.cookies} target='_blank' rel='noreferrer'>
                {globalContent?.cookies}
              </a>
            </div>
          </div>
          <p className='separator-version'>|</p>
          <p className='footer-desktop-version'>
            {globalContent?.version} {packageInfo.version}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
