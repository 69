export const firstCharToUpperCase = (value: string): string => {
  return String(value).charAt(0).toUpperCase() + String(value).slice(1);
};

export const getLocaleDateString = (language: string): string => {
  const formats: { [key: string]: string } = {
    'en-PH': 'MM/dd/yyyy',
    'en-US': 'MM/dd/yyyy',
    'es-US': 'MM/dd/yyyy',
    'fr-CA': 'yyyy/MM/dd',
  };

  return formats[language] || 'dd/MM/yyyy';
};

export function replaceInObject<T>(obj: T, search: RegExp, replace: string): T {
  if (typeof obj !== 'object') return obj;

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string') {
      (obj as any)[key] = value.replace(search, replace);
    } else if (typeof value === 'object') {
      obj[key as keyof T] = replaceInObject(value, search, replace);
    }
  }

  return obj;
}
