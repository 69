import React from 'react';
import { FrontRealmConf, OpStateProps, PageData } from '../../models/interfaces';
import { GlobalContentLocalizedContent } from '../../services/localization/interfaces';
import HcaptchaPrivacyAndTermsLabels from './hcaptcha/hcaptcha-privacy-and-terms-label.legacy';
import LanguageSwitcher from './language-switcher/language-switcher';

export interface ICardFooterProps {
  globalContent: GlobalContentLocalizedContent;
  state: [opStateProps: OpStateProps, setOpState: React.Dispatch<React.SetStateAction<OpStateProps>>];
  page?: PageData;
  realmConf?: FrontRealmConf;
}
export default function CardFooter(props: ICardFooterProps): JSX.Element {
  const {
    page,
    realmConf,
    globalContent,
    state: [{ largeCard, showHcaptcha }, setOpState],
  } = props;

  return (
    <div className={`card-footer ${largeCard ? 'large' : 'small'}`}>
      <div className='footer-container'>
        {realmConf?.locales?.length > 0 && (
          <div className='lang-container'>
            <LanguageSwitcher
              globalContent={globalContent}
              realmLocales={page?.data?.hideLanguageSwitcher ? null : realmConf?.locales || ['en']}
              locale={page.data?.locale}
              setLoader={() => setOpState((value) => ({ ...value, isLoading: true }))}
            />
          </div>
        )}
        <HcaptchaPrivacyAndTermsLabels
          className='hideDesktop'
          globalContent={globalContent}
          showHcaptcha={showHcaptcha}
        />
      </div>
    </div>
  );
}
