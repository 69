import React from 'react';
import { GlobalContentLocalizedContent } from '../../../services/localization/interfaces';

export interface ProgressProps {
  activeStep: number;
  globalContent: GlobalContentLocalizedContent;
  withText?: boolean;
}
const AccountCreationSteps = (props: ProgressProps): JSX.Element => {
  const { activeStep, globalContent, withText = true } = props;

  return (
    <div className='stepper-container'>
      {withText && (
        <div className='stepper-text'>
          {`${globalContent.register}: `}
          {globalContent.current_step?.replace('${activeStep}', activeStep.toString())}
        </div>
      )}
      <div className='stepper-wrapper'>
        <div className={`progress1 ${activeStep > 1 ? 'completedLine' : ''}`}></div>
        <div className={`progress2 ${activeStep > 2 ? 'completedLine' : ''}`}></div>
        {[1, 2, 3].map((step) => {
          return (
            <div
              className={`step-indicator 
                            ${activeStep > step ? 'completed' : ''} 
                            ${activeStep === step ? 'currentStep' : ''}`}
              key={step}
            >
              {step}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountCreationSteps;
