import { ProviderName } from '@sodexo-connect/sap-cdc-client/src/interfaces';
import React from 'react';
import { ReactComponent as SodexoConnect } from '../../../../../public/images/sodexo_connect_logo-v1.1.svg';
import { FinalOpProps, PageData } from '../../../../models/interfaces';
import LegacyTemplate from '../../../theme/template.legacy';
import CardFooter from '../../card-footer';
import Footer from '../../footer/footer.legacy';
import HcaptchaPrivacyAndTermsLabels from '../../hcaptcha/hcaptcha-privacy-and-terms-label.legacy';
import AccountCreationSteps from '../../oidc-provider/account-creation-steps';
import AccountLinkingFooter from '../../oidc-provider/account-linking-footer/account-linking-footer';

type LegacyOpLayoutProps = {
  children: any;
  data: FinalOpProps<PageData, any>;
};

const OpLayout = ({ children, data }: LegacyOpLayoutProps): JSX.Element => {
  const {
    globalContent,
    pageContent,
    state: [{ activeStep, cardIcon, className, showHcaptcha, isLoading, largeCard }],
    page: {
      data: { isAccountPairing, tp_idp_name },
    },
    footerUrl,
  } = data;

  const cardSize = largeCard ? ' large-card ' : ' small-card ';
  const cardClassName = 'flex container column center' + cardSize + className;

  return (
    <LegacyTemplate>
      <div className='img-sodexo'>
        <SodexoConnect aria-label='sodexo-connect-logo' />
      </div>
      <div className='flex flex-0-desktop container center column'>
        <div className={cardIcon ? 'title card-icon' : 'title'}>
          <h1>{pageContent?.main_title || globalContent?.title_welcome_to_sc}</h1>
          {pageContent?.subtitle && <h2>{pageContent.subtitle}</h2>}
          {activeStep > 0 && <AccountCreationSteps activeStep={activeStep} globalContent={globalContent} />}
        </div>
        <div className='card'>
          <div className='card-border'></div>
          <div className='card-content'>
            <div className={cardClassName}>
              {children}
              <HcaptchaPrivacyAndTermsLabels
                className='hideMobile'
                globalContent={globalContent}
                showHcaptcha={showHcaptcha}
              />
              <AccountLinkingFooter
                show={isAccountPairing}
                linkingWith={globalContent.linking_with}
                tp_idp_name={tp_idp_name as ProviderName}
              />
            </div>
          </div>
          <div className={isLoading ? 'card-loader' : ''}></div>
        </div>
        <CardFooter {...data} />
      </div>
      <Footer clientId={data.clientId} globalContent={data.globalContent} footerUrl={footerUrl} />
    </LegacyTemplate>
  );
};

export default OpLayout;
