import React from 'react';
import { GlobalContentLocalizedContent } from '../../../services/localization/interfaces';

export interface HcaptchaPrivacyAndTermsLabelsProps {
  className?: string;
  globalContent: GlobalContentLocalizedContent;
  showHcaptcha: boolean;
}
const HcaptchaPrivacyAndTermsLabels = ({
  className,
  globalContent,
  showHcaptcha,
}: HcaptchaPrivacyAndTermsLabelsProps): JSX.Element => {
  if (!showHcaptcha) return <></>;

  const [firstSeparator, secondeSeparator] =
    globalContent.hcaptcha_credit.indexOf('${privacy_policy}') <
    globalContent.hcaptcha_credit.indexOf('${terms_of_service}')
      ? ['${privacy_policy}', '${terms_of_service}']
      : ['${terms_of_service}', '${privacy_policy}'];
  const [firstPartSentence, secondPart] = globalContent.hcaptcha_credit.split(firstSeparator);
  const [middlePartSentence, lastPartSentence] = secondPart.split(secondeSeparator);

  return (
    <p
      className={`helper-text hcaptcha${className ? ' ' + className : ''}`}
      style={{ margin: '24px 0 0 0', fontSize: 12, lineHeight: '16px', textAlign: 'left' }}
    >
      {firstPartSentence}
      <a
        className='text-link'
        href='https://www.hcaptcha.com/privacy'
        target='_blank'
        rel='noopener noreferrer'
        key='privacy'
      >
        {globalContent.privacy_policy}
      </a>
      {middlePartSentence}
      <a
        className='text-link'
        href='https://www.hcaptcha.com/terms'
        target='_blank'
        rel='noopener noreferrer'
        key='terms'
      >
        {globalContent.terms_of_service}
      </a>
      {lastPartSentence}
    </p>
  );
};

export default HcaptchaPrivacyAndTermsLabels;
