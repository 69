import { ProviderName } from '@sodexo-connect/sap-cdc-client/src/interfaces';
import React from 'react';
import { ReactComponent as Apple } from '../../../../../public/images/apple-v1.svg';
import { ReactComponent as Facebook } from '../../../../../public/images/facebook.svg';
import { ReactComponent as Google } from '../../../../../public/images/google-single-color-v1.svg';
import { firstCharToUpperCase } from '../../../utils';

const AccountLinkingFooter = (props: {
  tp_idp_name: ProviderName;
  linkingWith: string;
  show: boolean;
}): JSX.Element => {
  const { show, linkingWith, tp_idp_name } = props;
  if (!show) return <></>;

  return (
    <div className='account-linking-footer-container'>
      <div className='account-linking-footer'>
        {tp_idp_name === 'google' && <Google fill='white' />}
        {tp_idp_name === 'facebook' && <Facebook fill='white' />}
        {tp_idp_name === 'apple' && <Apple fill='white' />}
        <p>
          <b>{`${linkingWith} ${firstCharToUpperCase(tp_idp_name)}`}</b>
        </p>
      </div>
    </div>
  );
};

export default AccountLinkingFooter;
